import { Collapse, Modal } from 'bootstrap';

function setShowPasswordEvent() {
  document.querySelectorAll(".show-password").forEach(function(elem) {
    elem.addEventListener('click', function(e) {
      var targetInput = elem.parentElement.parentElement.querySelector('.password-input')
      if (elem.checked) {
        targetInput.setAttribute("type", "text");
      } else {
        targetInput.setAttribute("type", "password");
      }
    });
  });
}

function setAutoCompleteEvent(autoCompleteAddress) {
  autoCompleteAddress.addEventListener('click', function(elem) {
    // 登録画面と修正画面でidが異なるため
    const targetElem = document.querySelector('#user_company_attributes_postal_code, #company_postal_code');
    const postalCode = targetElem.value;
    if (postalCode.length > 0) {
      fetch(`/users/address.json?postal_code=${postalCode}`)
        .then((response) => {
          return response.json();
        }).then((address) => {
          document.querySelector('#user_company_attributes_prefecture_cd, #company_prefecture_cd').value = address['prefectureCd'];
          document.querySelector('#user_company_attributes_city, #company_city').value = address['city'];
          document.querySelector('#user_company_attributes_address1, #company_address1').value = address['town'];
        });
    }
  });
}

window.addEventListener("load", () => {
  setShowPasswordEvent();
  const autoCompleteAddress = document.getElementById('auto-complete-address');
  if (autoCompleteAddress) {
    setAutoCompleteEvent(autoCompleteAddress);
  }
  const user_registration_form = document.querySelector("#user_registration_form");
  if (user_registration_form) {
    user_registration_form.addEventListener('submit', (event) => {
      event.stopPropagation();
      event.preventDefault();

      // フォームデータを取得
      const formData = new FormData(user_registration_form);
      const manualCheck = document.getElementById('manual-check-alert-modal').classList.contains('show');
      formData.append('manual_check', manualCheck);

      const headers = {
        'X-Requested-With': 'XMLHttpRequest'
      };
      // CSRFトークンを取得
      const tokenElem = document.querySelector('meta[name="csrf-token"]');
      // テスト環境ではcsrf-tokenが存在しないため、存在しない場合は追加しない
      if (tokenElem) {
        const csrfToken = tokenElem.getAttribute('content');
        headers['X-CSRF-Token'] = csrfToken;
      }

      // fetchのオプションを設定
      const fetchOptions = {
        method: user_registration_form.method || 'POST',
        headers: headers,
        body: formData,
        credentials: 'same-origin'
      };

      fetch(user_registration_form.action, fetchOptions)
        .then(response => {
          if (response.ok) {
            if (response.status === 201) {
              // レスポンスヘッダーからリダイレクト先を取得
              const location = response.headers.get('location');
              if (location) {
                window.location.href = location;
              } else {
                console.error('レスポンスにLocationヘッダーが含まれていません。');
              }
            } else {
              // モーダルを表示
              return response.text().then(text => {
                const modal = new Modal(document.getElementById('manual-check-alert-modal'));
                document.getElementById('manual-check-message-list').innerHTML = text;
                modal.show();
              });
            }
          } else {
            if (response.status === 422) {
              // バリデーションエラー時の処理
              return response.text().then(text => {
                user_registration_form.innerHTML = text;
                setShowPasswordEvent();
                setAutoCompleteEvent(document.getElementById('auto-complete-address'));
              });
            } else {
              // その他のエラー時の処理
              window.location.href = '/500.html';
            }
          }
        })
        .catch(error => {
          console.error('Fetchエラー:', error);
          window.location.href = '/500.html';
        });
    });
  }

  function clearPrefectureChecks() {
    document.querySelectorAll('input[name="paid_user[prefecture_cds][]"][checked="checked"], input[name="migrate_plan[prefecture_cds][]"][checked="checked"]').forEach(function(elem) {
      elem.checked = false;
    });
  }

  const prefecutreCollapse = document.getElementById('prefecture-collapse');
  if (prefecutreCollapse) {
    var bsCollapse = new Collapse(prefecutreCollapse, {
      toggle: false
    });
    // プラン選択時の都道府県選択の表示制御
    // 都道府県プラン選択で、都道府県を洗濯している状態でプラン変更時は都道府県選択をクリアする
    document.querySelectorAll('#paid_user_plan_cd_1, #migrate_plan_plan_cd_1').forEach(function(elem) {
      elem.addEventListener('change', function(event) {
        if (event.target.checked) {
          clearPrefectureChecks();
          bsCollapse.hide();
        } else {
          bsCollapse.show();
        }
      });
    });
    document.querySelectorAll('#paid_user_plan_cd_2, #migrate_plan_plan_cd_2').forEach(function(elem) {
      elem.addEventListener('change', function(event) {
        if (event.target.checked) {
          bsCollapse.show();
        } else {
          clearPrefectureChecks();
          bsCollapse.hide();
        }
      });
    });
  }

  const isOtherContractDetailsCollapse = document.getElementById('migrate-other-contract-details-collapse');
  if (isOtherContractDetailsCollapse) {
    var cntractDetailCollapse = new Collapse(isOtherContractDetailsCollapse, {
      toggle: false
    });
    var otherContractDetailsTextArea = document.getElementById('migrate_plan_other_contract_details');
    document.getElementById('migrate_plan_is_other_contract_details').addEventListener('change', function(event) {
      if (event.target.checked) {
        cntractDetailCollapse.show();
        otherContractDetailsTextArea.disabled = false;
      } else {
        cntractDetailCollapse.hide();
        otherContractDetailsTextArea.disabled = true;
      }
    });
  }
  const periodInput = document.getElementById('contract-period-input');
  if (periodInput) {
    document.getElementById('paid_user_payment_method_cd_1').addEventListener('change', function(event) {
      const threeMonth = document.querySelector('#paid_user_contract_period_3');
      if (event.target.checked && threeMonth) {
        threeMonth.setAttribute('id', 'paid_user_contract_period_1');
        threeMonth.setAttribute('value', '1');
        const label = document.querySelector('label[for="paid_user_contract_period_3"]');
        label.setAttribute('for', 'paid_user_contract_period_1');
        label.innerHTML = '1ヶ月'
      }
    });
    document.getElementById('paid_user_payment_method_cd_2').addEventListener('change', function(event) {
      const oneMonth = document.querySelector('#paid_user_contract_period_1');
      if (event.target.checked && oneMonth) {
        oneMonth.setAttribute('id', 'paid_user_contract_period_3');
        oneMonth.setAttribute('value', '3');
        const label = document.querySelector('label[for="paid_user_contract_period_1"]');
        label.setAttribute('for', 'paid_user_contract_period_3');
        label.innerHTML = '3ヶ月'
      }
    });
  }

  const cancellationTransferInput = document.getElementById('reason_for_cancellation_4');
  if (cancellationTransferInput) {
    const cancellationCompetitorSelect = document.getElementById('paid_user_reason_for_cancellation_competitor_cd');
    var bsCollapse = new Collapse(cancellationCompetitorSelect, {
      toggle: false
    });
    if (cancellationTransferInput.checked) {
      bsCollapse.show();
    } else {
      bsCollapse.hide();
    }
    document.getElementById('reason_for_cancellation_4').addEventListener('change', function(event) {
      if (event.target.checked) {
        bsCollapse.show();
      } else {
        bsCollapse.hide();
      }
    });
  }

  const radioButtons = document.querySelectorAll('.toggle-radio');
  const radioButtonsLastChecked = {};

  radioButtons.forEach(function(radio) {
    radio.addEventListener('click', function(event) {
      const name = this.name;
      if (radioButtonsLastChecked[name] === this) {
        this.checked = false;
        radioButtonsLastChecked[name] = null;
      } else {
        radioButtonsLastChecked[name] = this.checked ? this : null;
      }
    });
  });
});
